body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100% !important;
  margin: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  height: 100%;
}

.card-form .ant-card-head {
  background-color: #ff7d26;
  color: #fff;
  font-size: 20px;
  font-family: "Calibri";
}

.ant-card-body {
  border: none;
}
.ant-form-item-label {
  padding: 0 !important;
}
.ant-form-item-label > label {
  font-weight: bold;
  font-size: 11px !important;
}

.ant-menu-dark .ant-menu-item-selected {
  background-color: #da5800;
}

.ant-layout .ant-layout-sider-trigger {
  background-color: #ff7d26;
  border: 1px solid #ffc49d;
}

.listing {
  border: 2px solid #ffc49d;
}

.login-card .ant-card-body {
  border: 0px;
}

.forgot-password-card .ant-card-body {
  border: 0px;
}
.reset-password-card .ant-card-body {
  border: 0px;
}

.ant-layout-sider-children .ant-menu .ant-menu-item {
  padding-left: 10px !important;
}

.custom-icon-blue {
  border: 2px solid #1890ff;
  border-radius: 50%;
  padding: 5px;
  background-color: #1890ff;
  color: white;
  font-size: 16px;
}

.custom-icon-green {
  border: 2px solid #01b875;
  border-radius: 50%;
  padding: 5px;
  background-color: #01b875;
  color: white;
  font-size: 16px;
}

.custom-icon-red {
  border: 2px solid #e71616;
  border-radius: 50%;
  padding: 5px;
  background-color: #e71616;
  color: white;
  font-size: 16px;
}
